import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
//import Img from "react-cloudinary-lazy-image"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import ListaRevistas from "../components/ListaRevistas"

// import loadable from "@loadable/component"

// const Img = loadable(() => import("react-cloudinary-lazy-image"))

const RevistasIndex = ({ data }) => {
  const RevistasNew = data.strapi.revistasNewIndices
    .sort(
      (a, b) =>
        b.fecha.replace(/(.*)\.[^.]+$/, "$1") -
        a.fecha.replace(/(.*)\.[^.]+$/, "$1")
    )
    .map(x => {
      const Tapas = x.tapas
        .sort(
          (a, b) =>
            b.name.replace(/(.*)\.[^.]+$/, "$1") -
            a.name.replace(/(.*)\.[^.]+$/, "$1")
        )
        .map(x => {
          const number = x.name.replace(/(.*)\.[^.]+$/, "$1")
          const n = number.replace(/001/g, "")

          // const aspectRatio = x.width / x.height

          return (
            <a href={`/revistas/lagauchita${n}.pdf`}>
              <img
                data-sizes="auto"
                className="lazyload blur-up"
                key={x.name}
                src={`https://res.cloudinary.com/lagexpress/image/upload/w_20,f_auto,q_auto,e_trim:35:white/${x.provider_metadata.public_id}`}
                data-src={`https://res.cloudinary.com/lagexpress/image/upload/w_auto,f_auto,q_auto,r_25,e_trim:35:white/${x.provider_metadata.public_id}`}
                alt={x.alternativeText ? x.alternativeText : ""}
              />
              <p>La Gauchita Nº{n}</p>
            </a>
          )
        })

      return (
        <div>
          <hr id={x.fecha} />
          <h3>{x.fecha}</h3>
          <hr />
          <div className="revistas">{Tapas}</div>
        </div>
      )
    })
  const Revistas = data.strapi.revistasIndices
    .sort(
      (a, b) =>
        b.fecha.replace(/(.*)\.[^.]+$/, "$1") -
        a.fecha.replace(/(.*)\.[^.]+$/, "$1")
    )
    .map(x => {
      const Tapas = x.tapas
        .sort(
          (a, b) =>
            b.name.replace(/(.*)\.[^.]+$/, "$1") -
            a.name.replace(/(.*)\.[^.]+$/, "$1")
        )
        .map(x => {
          const number = x.name.replace(/(.*)\.[^.]+$/, "$1")
          const n = number.replace(/001/g, "")

          return (
            <Link to={`/revistas/${n}`}>
              {/* <Img key={x.name} fluid={x.imageFile.childImageSharp.fluid} /> */}
              {/* <Img
                cloudName={"lagexpress"}
                imageName={`${x.provider_metadata.public_id}.jpg`}
                fluid={{
                  maxWidth: "auto",
                }}
                urlParams={"r_20"}
              /> */}

              <img
                data-sizes="auto"
                className="lazyload blur-up"
                key={x.name}
                src={`https://res.cloudinary.com/lagexpress/image/upload/w_20,f_auto,q_auto,e_trim:35:white/${x.provider_metadata.public_id}`}
                data-src={`https://res.cloudinary.com/lagexpress/image/upload/w_auto,f_auto,q_auto,r_20,e_trim:35:white/${x.provider_metadata.public_id}`}
                alt={x.alternativeText ? x.alternativeText : ""}
              />

              <p>La Gauchita Nº{n}</p>
            </Link>
          )
        })

      return (
        <div>
          <hr id={x.fecha} />
          <h3>{x.fecha}</h3>
          <hr />
          <div className="revistas">{Tapas}</div>
        </div>
      )
    })

  return (
    <Layout>
      <GatsbySeo
        title="Revistas"
        titleTemplate="%s | Eduardo Ceballos"
        description="Revista La Gauchita"
        canonical="https://eduardoceballos.com/revistas"
        openGraph={{
          url: `https://eduardoceballos.com/revistas`,
          title: `Revista La Gauchita`,
          description: `Revista La Gauchita`,
          images: [
            {
              url: `https://eduardoceballos.com/seo/revistas.png`,
              alt: `Revista La Gauchita`,
            },
          ],
          site_name: "Eduardo Ceballos",
        }}
        twitter={{
          handle: "@PoetadeSalta",
          site: "@PoetadeSalta",
          cardType: "summary_large_image",
        }}
      />
      <h1>Revista La Gauchita</h1>
      <ListaRevistas />
      {RevistasNew}
      {Revistas}
    </Layout>
  )
}

export const query = graphql`
  {
    strapi {
      revistasIndices {
        fecha
        tapas {
          url
          name
          alternativeText
          provider_metadata
        }
      }
      revistasNewIndices {
        fecha
        tapas {
          url
          name
          alternativeText
          provider_metadata
          width
          height
        }
      }
    }
  }
`

export default RevistasIndex
