import React from "react"
import { Link } from "gatsby"

const ListaRevistas = () => (
    <div>
        <div className="revistas-menu-flex-2">
            <Link to="/revistas/#2024">2024</Link>
            <Link to="/revistas/#2023">2023</Link>
            <Link to="/revistas/#2022">2022</Link>
            <Link to="/revistas/#2021">2021</Link>
        </div>
        <div className="revistas-menu-flex-1">
            <Link to="/revistas/#2020">2020</Link>
            <Link to="/revistas/#2019">2019</Link>
            <Link to="/revistas/#2018">2018</Link>
            <Link to="/revistas/#2017">2017</Link>
            <Link to="/revistas/#2016">2016</Link>
            <Link to="/revistas/#2015">2015</Link>
            <Link to="/revistas/#2014">2014</Link>
            <Link to="/revistas/#2013">2013</Link>
            <Link to="/revistas/#2012">2012</Link>
            <Link to="/revistas/#2011">2011</Link>
        </div>
        <div className="revistas-menu-flex-2">
            <Link to="/revistas/#2002">2002</Link>
            <Link to="/revistas/#2001">2001</Link>
            <Link to="/revistas/#2000">2000</Link>
            <Link to="/revistas/#1999">1999</Link>
            <Link to="/revistas/#1998">1998</Link>
            <Link to="/revistas/#1997">1997</Link>
            <Link to="/revistas/#1996">1996</Link>
            <Link to="/revistas/#1995">1995</Link>
            <Link to="/revistas/#1994">1994</Link>
            <Link to="/revistas/#1993">1993</Link>
        </div>
    </div>
)

export default ListaRevistas
